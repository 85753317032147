import React from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import { Slide, Zoom } from "react-swift-reveal";

const tiersData = [
  {
    name: "Tier 01",
    balanceRange: "0 - 500 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "2.5%" },
      { name: "Gold Elite", period: 30, roi: "9%" },
      { name: "Platinum Elite", period: 60, roi: "20%" },
      { name: "Titanium Elite", period: 90, roi: "33%" },
      { name: "Diamond Elite", period: 180, roi: "70%" },
    ],
  },
  {
    name: "Tier 02",
    balanceRange: "501 - 2000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "2.75%" },
      { name: "Gold Elite", period: 30, roi: "9.9%" },
      { name: "Platinum Elite", period: 60, roi: "22%" },
      { name: "Titanium Elite", period: 90, roi: "36.3%" },
      { name: "Diamond Elite", period: 180, roi: "77%" },
    ],
  },
  {
    name: "Tier 03",
    balanceRange: "2001 - 5000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3%" },
      { name: "Gold Elite", period: 30, roi: "10.8%" },
      { name: "Platinum Elite", period: 60, roi: "24%" },
      { name: "Titanium Elite", period: 90, roi: "39.6%" },
      { name: "Diamond Elite", period: 180, roi: "84%" },
    ],
  },
  {
    name: "Tier 04",
    balanceRange: "5001 - 10,000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.25%" },
      { name: "Gold Elite", period: 30, roi: "11.7%" },
      { name: "Platinum Elite", period: 60, roi: "26%" },
      { name: "Titanium Elite", period: 90, roi: "42.9%" },
      { name: "Diamond Elite", period: 180, roi: "91%" },
    ],
  },
  {
    name: "Tier 05",
    balanceRange: "10,001 - 25,000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.5%" },
      { name: "Gold Elite", period: 30, roi: "12.6%" },
      { name: "Platinum Elite", period: 60, roi: "28%" },
      { name: "Titanium Elite", period: 90, roi: "46.2%" },
      { name: "Diamond Elite", period: 180, roi: "98%" },
    ],
  },
  {
    name: "Tier 06",
    balanceRange: "25,001 - 50000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.75%" },
      { name: "Gold Elite", period: 30, roi: "13.5%" },
      { name: "Platinum Elite", period: 60, roi: "30%" },
      { name: "Titanium Elite", period: 90, roi: "49.5%" },
      { name: "Diamond Elite", period: 180, roi: "105%" },
    ],
  },
  {
    name: "Tier 07",
    balanceRange: "50,000+ USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "4%" },
      { name: "Gold Elite", period: 30, roi: "14.4%" },
      { name: "Platinum Elite", period: 60, roi: "32%" },
      { name: "Titanium Elite", period: 90, roi: "52.8%" },
      { name: "Diamond Elite", period: 180, roi: "112%" },
    ],
  },
];

const isEven = (num) => {
  return (num + 1) % 2 === 0;
};

const Tier = ({ name, balanceRange, packages, index }) => (
  <div className={`row ${isEven(index) ? "" : "row-reverse"}`}>
    <div className="col-md-5 column left">
      <div className="title">Balance: {balanceRange}</div>
      <Slide up duration={2000} delay={100}>
        <div className="table">
          <div className="tableHead">
            <div>Pool</div>
            <div>Investment Period</div>
            <div>Return on Investment</div>
          </div>
          {packages.map((category) => (
            <div key={category.name} className="tableBody">
              <div className="package">{category.name}</div>
              <div>{category.period} Days</div>
              <div>{category.roi}</div>
            </div>
          ))}
        </div>
      </Slide>
    </div>
    <div className="col-md-2">
      <div className="name">{name}</div>
    </div>
    <div className="col-md-5"></div>
  </div>
);

function ProfitTiers() {
  return (
    <>
      <Hero
        heading="Climbing The Crypto Tiers"
        subHeading="Unlocking Profits on MyArbit Platform"
      />
      <div className="container">
        <div className="profit">
          <div className="circle pinkCircle"></div>
          <Zoom duration={3000} delay={100}>
            <Heading title="Impressive Profit Tiers Structure for Cryptocurrency Ventures" />
          </Zoom>
          {tiersData.map((tier, index) => (
            <Tier key={tier.name} {...tier} index={index} />
          ))}
        </div>
      </div>
    </>
  );
}

export default ProfitTiers;
