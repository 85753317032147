import React from "react";
import { Link } from "react-router-dom";

function Post({
  featuredImg,
  userImg,
  date,
  name,
  title,
  shortDesc,
  postDetailUrl,
}) {
  return (
    <div className="blog">
      <img src={featuredImg} className="featuredImg" />
      <div className="postHead">
        <div className="nameWrapper">
          <img src={userImg} />
          {name}
        </div>
        <span>{date}</span>
      </div>
      <div className="title">{title}</div>
      <div className="shortDesc">{shortDesc}</div>
      <Link to={postDetailUrl} className="link">
        Read More
      </Link>
    </div>
  );
}

export default Post;
