import { useState } from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import { Link } from "react-router-dom";
import axios from "axios";

function Contact() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    } else if (/[a-zA-Z]/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number should only contains digit";
    } else if (!/^\d{11}$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone Number is invalid (should be 11 digits)";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      let formData = {
        name: fullName,
        from: email,
        phone: phoneNumber,
        message: message,
      };

      console.log("hdbsfbsdh", formData);

      axios
        .post("https://my-arbit-558be498ca49.herokuapp.com/api/user/contactUsMail", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("Form submitted successfully");
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setMessage("");
            setFormSubmit(true);
          } else {
            console.error("Error submitting form");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Hero heading="Contact Us" subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies." />
      <section className="contactUs">
        <div className="container">
          <div className="circle pinkCircle"></div>
          <div className="gridWrapper">
            <div className="leftWrapper">
              <Heading title="Contact Information" />
              <div className="subHeading">Fill up the form and our team will get back to you within 24 hours.</div>
              <div className="infoBox">
                <div className="label">Contact</div>
                <div className="info">
                  <Link to="tel:+971 56 729 1949">+971 56 729 1949</Link>
                </div>
                <div className="label">Email</div>
                <div className="info">
                  <Link to="mailto:support@myarbit.com">support@myarbit.com</Link>
                </div>
                <div className="label">Location</div>
                <div className="info pb-0">
                  <Link
                    to="https://www.google.com/maps/place/227+Al+Thanya+St+-+Al+Manara+-+Dubai+-+United+Arab+Emirates/@25.1347833,55.2105619,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6bcdc732a6a5:0x44d01447b19fadec!8m2!3d25.1347785!4d55.2131368!16s%2Fg%2F11gxrctbd4?entry=ttu"
                    target="_blank"
                  >
                    DMCC Plot No. 2-27, Al Thanyah-Jumeirah Lake Towers
                  </Link>
                </div>
              </div>
            </div>
            <div className="rightWrapper">
              <div className="heading">Get in Touch</div>
              <div className="subHeading">We are here for you. How we can help?</div>
              <form onSubmit={handleSubmit}>
                <div className="label">Full Name</div>
                <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                {errors.fullName && <div className="error">{errors.fullName}</div>}

                <div className="label">Email</div>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                {errors.email && <div className="error">{errors.email}</div>}
                <div className="label">Phone Number</div>
                <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}

                <div className="label message">Message</div>
                <textarea name="" id="" cols="30" rows="7" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                <input type="submit" value="Submit Now" className="submitBtn" />
              </form>
              {formSubmit ? <div className="success">Form Submit Successfully.</div> : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
