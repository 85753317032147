import FeaturedImage1 from "../../assets/images/image-1.png";
import FeaturedImage2 from "../../assets/images/image-2.png";
import FeaturedImage3 from "../../assets/images/image-3.png";
import User1 from "../../assets/images/user-1.png";
import User2 from "../../assets/images/user-2.png";
import User3 from "../../assets/images/user-3.png";

const post = [
  {
    id: 1,
    featuredImg: FeaturedImage1,
    userImg: User1,
    name: "Alina",
    date: "12 July 2023",
    title: "The Fundamentals of Crypto Arbitrage",
    shortDesc:
      "In the rapidly evolving world of cryptocurrencies, savvy investors are always on the lookout for profitable opportunities. One such strategy gaining popularity is crypto arbitrage. In this blog, we'll delve into the fundamentals of crypto arbitrage, explore its inner workings, discuss popular strategies, analyze risks and challenges, and provide essential tips for successful crypto arbitrage trading.",
    postDetailUrl: "/postDetail/1",
  },
  {
    id: 2,
    featuredImg: FeaturedImage2,
    userImg: User2,
    name: "Alex Banner",
    date: "27 July 2023",
    title: "The Role of Tech in Crypto Arbitrage",
    shortDesc:
      "In the world of investing, the old adage 'don't put all your eggs in one basket' holds true. Diversification is a proven strategy that aims to minimize risks and optimize returns by spreading investments across different assets. When it comes to the dynamic realm of cryptocurrencies, diversification takes on even greater significance. In this blog, we will explore the significance of diversification in investment, apply diversification principles to crypto arbitrage, and understand how creating a balanced and diversified portfolio can help investors seize opportunities while mitigating risks.",
    postDetailUrl: "/postDetail/2",
  },
  {
    id: 3,
    featuredImg: FeaturedImage3,
    userImg: User3,
    name: "Naina",
    date: "27 July 2023",
    title: "Diversification in Crypto Arbitrage Reducing Risks, Maximizing Gain",
    shortDesc: "In the fast-paced and ever-evolving world of crypto currencies, technology plays a pivotal role in unlocking the fast-paced and ever-evolving world of",
    postDetailUrl: "/postDetail/3",
  },
  {
    id: 4,
    featuredImg: FeaturedImage1,
    userImg: User1,
    name: "Alina",
    date: "11 July 2024",
    title: "Why MyArbit Is the Best Investing Platform for Crypto Enthusiasts",
    shortDesc:
      "The financial environment has changed due to cryptocurrency, which presents amazing potential for investors to increase their wealth. To navigate this uncertain market, enthusiasts of cryptocurrencies need a dependable, safe, and easy-to-use platform",
    postDetailUrl: "/postDetail/4",
  },
  {
    id: 5,
    featuredImg:  FeaturedImage2,
    userImg: User1,
    name: "Alina",
    date: "15 July 2024",
    title: "Long-term vs Short-term Crypto Investment Strategies",
    shortDesc:
      "With its amazing potential for profit and growth, crypto investment has shaken the financial world. However, choosing between a long-term and short-term investing approach might be difficult. Knowing the advantages and disadvantages of each strategy will enable you to choose wisely.",
    postDetailUrl: "/postDetail/5",
  },
];

export default post;
