import React, { useState } from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import Posts from "../utils/data/post";
import Post from "../components/Post";
import { Slide } from "react-swift-reveal";

function Blog() {
  const [showAllPosts, setShowAllPosts] = useState(false);

  const sortedPosts = [...Posts].sort((a, b) => new Date(b.date) - new Date(a.date));

  const initialPosts = sortedPosts.slice(0, 3);
  const displayedPosts = showAllPosts ? sortedPosts : initialPosts;

  return (
    <>
      <Hero heading="News and Heights" subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies." />
      <div className="blogs blogPage">
        <div className="container">
          <Slide left duration={3000} delay={100}>
            <Heading title="Recent Blog Post" />
          </Slide>
          <div className="gridWrapper">
            {displayedPosts.map((post) => (
              <Post {...post} key={post.id} />
            ))}
          </div>
        </div>
      </div>
      {!showAllPosts && (
        <div className="see-more-container">
          <button className="see-more" onClick={() => setShowAllPosts(true)}>
            See More
          </button>
        </div>
      )}
    </>
  );
}

export default Blog;
